<template>
	<div class="setting-item-content d-f ai-c jc-sb">
		<span>管理员身份证后6位</span>
		<el-input v-model="value" style="width:300px" placeholder="请输入后6位"></el-input>
	</div>
</template>

<script>
import { getIdentityNo, setIdentityNo } from '@/views/common/store'
import { createNamespacedHelpers } from "vuex";
import { getSchoolId } from '../common/store';
// import CommonRangeTIme from "../../components/commonRangeTime.vue";
const { mapActions } = createNamespacedHelpers("setting");
// 管理员身份号
export default {
	data() {
		return {
			value: getIdentityNo() || ""
		}
	},

	methods: {
		...mapActions(["changePutIdentity"]),
		setIdentity() {
			return new Promise((resolve, reject) => {
				if (
					!/^[0-9]{5}(\d|X|x)$/.test(
						this.value
					)
				) {
					this.$message.error("请输入身份证号后6位");
					reject()
					return;
				}

				this.changePutIdentity({
					identityNo: this.value,
					id: getSchoolId()

				}).then(res => {
					setIdentityNo(this.value)
					resolve()


				}).catch(reject)



			})

		}
	},
	mounted() {

	}
}
</script>

<style></style>