<template>
  <div class="setting w-100_ h-100_">
    <div class="setting-content">
      <div class="setting-header d-f ai-c jc-sb">
        <span class="fw-b">系统设置</span>
        <el-button @click="save" type="primary">保存</el-button>
      </div>
      <div class="setting-body">
        <div class="setting-item">
          <div class="setting-title primary-title">账号及密码</div>
          <!-- <div class="setting-item-content d-f ai-c jc-sb">
            <span>当前手机号：{{ phone }}</span>
            <i @click="changePhone" class="el-icon-edit-outline c-p"></i>
          </div> -->
          <div class="setting-item-content d-f ai-c jc-sb">
            <span>修改登录密码</span>
            <i @click="fixPass" class="el-icon-edit-outline c-p"></i>
          </div>
          <div v-for="(item, index) in settingInfo.filter(it => it.paramNo == 4)" :key="item.id + index"
            class="setting-item-content d-f ai-c jc-sb">
            <span>{{ item.paramName }}</span>
            <el-switch @change="(val) => settingChange(item, val)" :value="item.checked"></el-switch>
          </div>
          <manageBack6Code ref="six"></manageBack6Code>
        </div>
        <div class="setting-item">
          <div class="setting-title primary-title">院系设置</div>
          <div class="setting-item-content d-f ai-c jc-sb c-p" @click="setDepart">
            <span>设置学院、系的名称</span>
            <i class="el-icon-arrow-right c-p"></i>
          </div>
          <el-popover placement="top" width="400" trigger="click" popper-class="student-body-h"
            v-model="batDeletevisible">
            <div><b>批量删除学生信息</b></div>
            <el-form label-width="60px" style="margin-top:20px">
              <el-form-item label="年度:">
                <el-select multiple v-model="batDelParams.enrolYearList" clearable>
                  <el-option v-for="item in yearsList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学院:">
                <el-select multiple v-model="batDelParams.academyIdList" clearable>
                  <el-option v-for="item in facultyList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="batDeleteCancel">取消</el-button>

              <el-button :disabled="!(batDelParams.enrolYearList.length || batDelParams.academyIdList.length)"
                type="primary" @click="batDeleteSure" size="mini">确定</el-button>

            </div>
            <div slot="reference" class="setting-item-content d-f ai-c jc-sb c-p">
              <span>毕业删除</span>
              <i class="el-icon-arrow-right c-p"></i>
            </div>
          </el-popover>
        </div>
        <div class="setting-item">
          <div class="setting-title primary-title">宿管类型设置</div>
          <div class="setting-item-content d-f ai-c jc-sb c-p">
            <my-tags v-for="(item, index) in settingInfo.filter(it => it.paramCode == 'dormType')" :key="item.id + index"
              :value="item.paramValue == '0' || !item.paramValue ? [] : item.paramValue.split('-')"
              @input="val => settingChange(item, val.join('-'), true)"></my-tags>
          </div>
        </div>
        <div class="setting-item">
          <div class="setting-title primary-title">回寝打卡设置</div>
          <div class="setting-item-content d-f ai-c jc-sb">
            <span>回寝{{ clockSwitch.paramName || "" }}</span>
            <el-switch @change="(val) => settingChange(clockSwitch, val)" :value="clockSwitch.checked"></el-switch>
          </div>
          <div v-if="clockSwitch.checked">

            <!-- <div style="margin: 15px 0" class="setting-s-timer d-f">
              <span style="padding: 0 15px 0 0">日期设置：</span>
              <div class="d-f ai-c">
                <div class="set-color primary"></div>
                <span class="set-name">打卡日</span>
              </div>
              <div class="d-f ai-c">
                <div class="set-color green"></div>
                <span class="set-name">节假日</span>
              </div>
            </div> -->
            <!-- <div style="margin-top: 16px; width: 800px">
              <my-canlander @backCanlanderList="backCanlanderList" :nums="2" :holidayList="holidayList" canIChecked
                :checkedList="days" @checked="onDayClick__back" @getYear="getYear" />
            </div> -->
            <div style="margin: 15px 0" class="setting-s-timer">
              打卡时间段设置：
              <el-time-picker is-range v-model="timeSet" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" placeholder="选择时间范围" @change="timePickerChage"
                :default-value="['2000-01-01 19:30', '2000-01-01 22:30']" value-format="HH:mm" format="HH:mm">
              </el-time-picker>
              <!-- <el-checkbox
              @change="jjrdkChange"
              v-model="timesClocks"
              style="margin: 0 10px"
            >
              节假日打卡</el-checkbox
            > -->
            </div>
            <!-- <div style="margin: 15px 0" class="setting-s-timer d-f ai-c">
            入学年度设置：
            <div style="width: 500px">
              <CommonRangeTIme
                v-model="schoolYearRange"
                :startName="'none'"
                :endName="'至'"
                :type="'year'"
                :clearable="false"
                @changeRange="schoolRangeChange"
              ></CommonRangeTIme>
            </div>
          </div> -->
          </div>
        </div>

        <div v-if="clockSwitch.checked === 'ddfdfs'" class="setting-item">
          <div class="setting-title primary-title">
            未打卡告警设置
            <!-- <span style="font-weight: 100; font-size: 14px"
              >(超过预设次数就告警)</span
            > -->
          </div>
          <div>
            <el-radio-group v-model="radio">
              <el-radio style="display: block; margin: 10px 0" v-for="item in cycleList" :label="item.id" :key="item.id"
                @change="unClickInput(item.id)">{{ item.name }}
                <el-input size="mini" style="margin: 0 10px; width: 100px" type="number"
                  @blur="unClickBlur($event, item.max)" v-model="cycleValue[item.id]" :max="item.max" :min="0"
                  @input="unClickInput(item.id)"></el-input>
                次
              </el-radio>
            </el-radio-group>
          </div>
          <div style="color: #999" class="fs-18 fw-b">
            <!-- 新设置次数不影响历史告警信息。 -->
          </div>
        </div>
        <div class="setting-item">
          <div class="setting-title primary-title">发通知</div>
          <notice></notice>
        </div>
        <div v-if="false" class="setting-item">
          <div class="setting-title primary-title">打卡设置</div>
          <div class="setting-item-content-s d-f ai-c jc-sb">
            <div style="width: 49%">
              <div class="setting-s-timer">
                时间段设置：
                <el-time-picker is-range v-model="timeSet" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间" placeholder="选择时间范围" @change="timePickerChage" value-format="HH:mm"
                  format="HH:mm">
                </el-time-picker>
                <el-checkbox @change="jjrdkChange" v-model="timesClocks" style="margin: 0 10px">
                  节假日打卡</el-checkbox>
              </div>
              <div v-if="false" class="setting-s-timer">
                <span>日期设置：<el-button style="padding: 5px 10px" class="d-f ai-c" type="primary"
                    :disabled="timeSet.length != 2" @click="addDateReange">新增</el-button>
                  <el-button :disabled="!dateRangeList.length" style="padding: 5px 10px" class="d-f ai-c" type="primary"
                    @click="saveRange">保存</el-button>
                </span>
              </div>
              <el-table v-if="false" height="250" border :data="dateRangeList">
                <el-table-column align="center" min-width="250px" label="日期">
                  <template slot-scope="scope">
                    <el-date-picker v-model="scope.row.value" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" @click="delDate(scope.$index)">
                      删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="false" style="margin-top: 16px; width: 49%">
              <vc-calendar :columns="$screens({ lg: 2 }, 1)" :attributes="dateRangeAttr" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="passwordVisible" width="300px" custom-class="other-dialog" top="147px" title="修改密码"
      :close-on-click-modal="false">
      <el-input style="margin: 10px 0" placeholder="请输入密码" v-model="newPassword" show-password></el-input>
      <el-input placeholder="请确认密码" v-model="confirmPassword" show-password></el-input>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="sureModifyPass" type="primary">确认</el-button>
        <el-button @click="passwordVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="phoneVisible" width="300px" custom-class="other-dialog" top="147px" title="修改手机号码"
      :close-on-click-modal="false">
      <el-input style="margin: 10px 0" placeholder="请输入手机号码" v-model="newPhone" type="phone"></el-input>
      <el-input placeholder="请确认手机号码" v-model="confirmPhone" type="phone"></el-input>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="sureModifyPhone" type="primary">确认</el-button>
        <el-button @click="phoneVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import manageBack6Code from './manageBack6Code.vue';
import MyTags from './tags.vue'
import { createNamespacedHelpers } from "vuex";
// import CommonRangeTIme from "../../components/commonRangeTime.vue";
const { mapActions, mapState } = createNamespacedHelpers("setting");
import dayjs from "dayjs";
// import MyCanlander from "../../components/canlander/canlander.vue";
import Notice from "./notice.vue";
import md5 from "../common/md5";
import { getPassWord, clear } from "../common/store";
const { mapState: studentMapState, mapActions: studentMapActions } =
  createNamespacedHelpers("student");
export default {
  name: "setting",
  components: {
    // MyCanlander,
    // CommonRangeTIme,
    Notice,
    MyTags,
    manageBack6Code
  },
  data() {
    return {
      radio: 0,
      cycleValue: {
        0: "",
        1: "",
      },
      batDelParams: {
        academyIdList: [],
        enrolYearList: []
      },
      dormTypeList: [],
      phoneVisible: false,
      storeYear: [new Date().getFullYear()],
      timesClocks: false,
      timesClockRange: [],
      passwordVisible: false,
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      newPhone: "",
      confirmPhone: "",
      holidayList: [],
      vacationList: [],
      settingInfo: [],
      clockSwitch: {},
      phone: "",
      modifyPhone: "",
      days: [], //  存储多选节假日作用
      unClockDays: [], //  非打卡节假日
      timeSet: [],
      schoolYearRange: [],
      batDeletevisible: false,
      range: {
        start: "",
        end: "",
      }, //  范围时间选择
      dateRangeList: [],
      allDaysByYears: [], //  记录所有日历记录
      cycleList: [
        { id: 0, name: "按月", max: 28 },
        { id: 1, name: "按周", max: 7 },
      ],
      T: null,
      attributes: [
        {
          dot: {
            style: {
              "background-color": "#e66700",
            },
          },
          dates: [],
        },
        {
          dot: {
            style: {
              "background-color": "#6dd400",
            },
          },
          dates: [],
        },
      ],
      rangeAttr: [
        {
          highlight: {
            start: {
              fillMode: "outline",
              contentStyle: { background: "#6dd400" },
            },
            base: {
              fillMode: "light",
            },
            end: {
              fillMode: "outline",
              contentStyle: { background: "#6dd400" },
            },
          },
          dates: {
            start: new Date(2021, 8, 1),
            end: new Date(2021, 8, 5),
          },
        },
      ],
      dateRangeAttr: [],
    };
  },
  methods: {
    ...studentMapActions(['deleteBatStudent', "getOrgList",]),
    ...mapActions([
      "getAllSettingMessage",
      "updateSettingInfo",
      "getCalanderInfo",
      "getCurrentSchoolMessage",
      "changeSchoolMessage",
      "changeDays",
      "getClockTimeRange",
      "batchPutRangeCalander",
      "addSettingInfo",
      "batChangeDays",
      "changePhoneBySetting",
    ]),
    batDeleteCancel() {
      this.batDelParams = {
        academyIdList: [],
        enrolYearList: []
      }
      this.batDeletevisible = false
    },
    batDeleteSure() {
      this.$confirm(`确定要删除所选范围的所有学生吗`, '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteBatStudent(this.batDelParams).then(res => {
          this.$message.success('批量删除成功')
        }).finally(() => {
          this.batDeleteCancel()
        })
      })
    },
    backCanlanderList(val) {
      if (val.find(it => it.month == 11)) {
        this.getCalanderInfo(val[0].year + 1).then(res => {
          this.timesClocks = false;
          this.allDaysByYears = res;
          this.holidayList = [];
          console.log(JSON.stringify(res));
          this.vacationList = [];
          this.timeSet = null;
          res.forEach((item) => {
            // if (item?.vacationFlag == 1) {
            //   this.vacationList.push(item.calendarDate);
            // }
            if (item?.holidaysFlag == 1) {
              if (item?.clockSetting) {
                this.timesClocks = true;
              }

              this.holidayList.push(item.calendarDate);
            }
            if (item?.clockFlag == "1") {
              // if (item?.clockSetting) {
              //   this.timesClocks = true;
              // }
              this.days.push(item.calendarDate);
              this.unClockDays.push(item.calendarDate);
            }

            if (item.clockSetting) {
              let s = item.clockSetting;
              this.timeSet = s
                .split(";")[1]
                .split("-")
                .map((it) => new Date("2021-01-01 " + it));
            }
          });
        })
      }
    },
    settingChange(item, val, isNotChecked) {
      this.updateSettingInfo({ ...item, paramValue: isNotChecked ? val : val ? "1" : "0" });
    },
    timePickerChage(val) {
      console.log(val);
      this.batchPutRangeCalander({
        ids: this.allDaysByYears
          .reduce((c, n) => (n.clockFlag == 1 ? [...c, n.id] : c), [])
          .join(","),
        clockSetting:
          val && val.length > 1
            ? "1;" +
            val
              .map((it) => dayjs("2000-01-01 " + it).format("HH:mm:ss"))
              .join("-")
            : "l;19:30:00-22:30:00",
      }).then(() => {
        this.getCanladerList();
      });
    },
    confirmFunc() {
      return new Promise((res) => {
        this.$prompt("请输入登录密码进行验证！", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
        }).then(({ value }) => {
          if (md5(value) == getPassWord()) {
            this.oldPassword = value;
            res();
          } else {
            this.$message.error("密码错误！");
          }
        });
      });
    },
    fixPass() {
      // this.confirmFunc().then(() => {
      this.passwordVisible = true;
      // this.$prompt("请输入密码", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputType: "password",
      // }).then(({ value: password }) => {
      //   this.changeSchoolMessage({ password });
      // });
      // });
    },
    jjrdkChange(val) {
      this.batchPutRangeCalander({
        ids: this.allDaysByYears
          .reduce(
            (c, n) =>
              val ||
                (n.holidaysFlag != 1 && n.clockFlag != "0" && n.vacationFlag != 1) // checkbox为ture？则每一天都要打卡，false则只有非节假日打卡
                ? [...c, n.id]
                : c,
            []
          )
          .join(","),
        clockSetting:
          this.timeSet && this.timeSet.length > 1
            ? "1;" +
            this.timeSet.map((it) => dayjs(it).format("hh:mm:ss")).join("-")
            : "",
      }).then(() => {
        this.getCanladerList();
      });
    },
    sureModifyPhone() {
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          this.newPhone
        )
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.newPhone != this.confirmPhone) {
        this.$message.error("手机号码输入不一致");
        return;
      }
      this.changePhoneBySetting({ phone: this.newPhone }).then(() => {
        this.phone = this.newPhone;
        this.phoneVisible = false;
      });
    },
    sureModifyPass() {
      if (!(this.newPassword && this.confirmPassword)) {
        this.$message.error("密码请填写完整");
      }
      if (this.newPassword == this.confirmPassword) {
        this.changeSchoolMessage({
          password: this.newPassword,
        }).then(() => {
          this.$message.success("密码修改成功");
          clear();
          this.$router.push("/login");
        });
      } else {
        this.$message.error("输入的新密码不一致");
      }
    },
    changePhone() {
      this.phoneVisible = true;
    },
    setDepart() {
      //  跳转设置院系的页面
      this.$router.push("/setting/system");
    },
    onDayClick(day) {
      console.log(day);
      for (let daysItem of this.allDaysByYears) {
        if (+new Date(daysItem.calendarDate) == +new Date(day)) {
          console.log(12, daysItem, this.allDaysByYears);
          const idx = this.days.findIndex((d) => d === day);
          this.changeDays({
            ...daysItem,
            clockSetting: idx < 0 ? this.allDaysByYears.find(it => it.clockSetting)?.clockSetting ?? "" : "",
            clockFlag: idx >= 0 ? 0 : 1,
          }).then(() => {
            if (idx >= 0) {
              this.days.splice(idx, 1);
            } else {
              this.days.push(day);
            }
          });
          break;
        }
      }
    },
    onUnClockDayClick(day) {
      for (let daysItem of this.allDaysByYears) {
        if (+new Date(daysItem.calendarDate) == +new Date(day)) {
          this.changeDays({
            ...daysItem,
            clockFlag: daysItem.clockFlag == "1" ? "0" : "1",
            clockSetting:
              this.timesClocks && this.timeSet && this.timeSet.length > 1
                ? "1;" +
                this.timeSet
                  .map((it) => dayjs(it).format("hh:mm:ss"))
                  .join("-")
                : "",
          }).then(() => {
            const idx = this.unClockDays.findIndex((d) => d === day);
            if (idx >= 0) {
              this.unClockDays.splice(idx, 1);
            } else {
              this.unClockDays.push(day);
            }
          });
        }
      }
    },
    addDateReange() {
      this.dateRangeList.push({ value: "" });
    },
    saveRange() {



      //  保存打卡时间段
      const times = {};
      let timesArr = [];
      //  获取时间段数据
      this.dateRangeList.forEach((item) => {
        let start = +item.value[0];
        const end = +item.value[1];
        while (start <= end) {
          times[start] = 1;
          start = start + 1000 * 60 * 60 * 24;
        }
      });
      const timesList = Object.keys(times).map(Number);

      for (let daysItem of this.allDaysByYears) {
        if (
          timesList.includes(
            +new Date(
              dayjs(daysItem.calendarDate).format("YYYY-MM-DD") + " 00:00:00"
            )
          )
        ) {
          //  更改时间段值
          timesArr.push(daysItem.id);
        }
      }
      this.batchPutRangeCalander({
        ids: timesArr.join(","),
        clockSetting:
          this.timeSet.map((it) => dayjs(it).format("hh:mm:ss")).join("-") +
          ",",
      }).then(() => {
        this.getCanladerList();
      });
    },
    delDate(index) {
      this.$delete(this.dateRangeList, index);
    },
    schoolRangeChange(dates) {
      const item = this.settingBaseinfo.find(
        (it) => it.paramCode == "schoolYearRange"
      );
      console.log(item);
      if (item) {
        this.updateSettingInfo({
          ...item,
          paramValue: dates.map((it) => new Date(it).getFullYear()).join("-"),
        });
      }
    },
    getYear(year) {
      // return;
      // if (!this.storeYear.includes(year)) {
      //   this.getCalanderInfo(year).then((res) => {
      //     this.storeYear.push(year);
      //     this.timesClocks = false;
      //     this.allDaysByYears = res;
      //     this.holidayList = [];
      //     console.log(res);
      //     this.vacationList = [];
      //     res.forEach((item) => {
      //       // if (item?.vacationFlag == 1) {
      //       //   this.vacationList.push(item.calendarDate);
      //       // }
      //       if (item?.holidaysFlag == 1) {
      //         console.log(item.calendarDate);
      //         if (item?.clockSetting) {
      //           this.timesClocks = true;
      //         }
      //         this.holidayList.push(item.calendarDate);
      //       }
      //       if (item?.clockFlag == "1") {
      //         // if (item?.clockSetting) {
      //         //   this.timesClocks = true;
      //         // }
      //         this.days.push(item.calendarDate);
      //         this.unClockDays.push(item.calendarDate);
      //       }
      //       if (item.clockSetting) {
      //         this.timeSet = item.clockSetting
      //           .split(";")[1]
      //           .split("-")
      //           .map((it) => new Date("2021-01-01 " + it));
      //       }
      //     });
      //   });
      // }
    },
    save() {
      // const params = [
      //   ...this.settingInfo.map((it) => ({
      //     ...it,
      //     paramValue: it.checked ? "1" : "0",
      //   })),
      // ];
      // this.updateSettingInfo(params);
      this.$refs.six.setIdentity().then(() => {
        setTimeout(() => {
          this.$message.success("保存成功！");
        }, 500);
      })

    },
    getCanladerList() {
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        this.attributes[1].dates = [];
        this.attributes[0].dates = [];
        this.days = [];
        this.unClockDays = [];
        this.getCalanderInfo().then((res) => {
          //  获取日历信息
          this.timesClocks = false;
          this.allDaysByYears = res;
          this.holidayList = [];
          console.log(JSON.stringify(res));
          this.vacationList = [];
          this.timeSet = null;
          res.forEach((item) => {
            // if (item?.vacationFlag == 1) {
            //   this.vacationList.push(item.calendarDate);
            // }
            if (item?.holidaysFlag == 1) {
              if (item?.clockSetting) {
                this.timesClocks = true;
              }

              this.holidayList.push(item.calendarDate);
            }
            if (item?.clockFlag == "1") {
              // if (item?.clockSetting) {
              //   this.timesClocks = true;
              // }
              this.days.push(item.calendarDate);
              this.unClockDays.push(item.calendarDate);
            }

            if (item.clockSetting) {
              let s = item.clockSetting;
              this.timeSet = s
                .split(";")[1]
                .split("-")
                .map((it) => new Date("2021-01-01 " + it));
            }
          });
        });
      }, 200);
    },
    unClickBlur(e, max) {
      if (e.target.value >= max) {
        console.log(11);
        e.target.value = max;
      }
    },
    unClickInput(id) {
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        this.updateSettingInfo({
          ...this.settingBaseinfo[0],
          paramValue:
            [
              this.cycleValue[0] && this.cycleValue[0] > 0
                ? this.cycleValue[0]
                : 0,
              this.cycleValue[1] && this.cycleValue[1] > 0
                ? this.cycleValue[1]
                : 0,
            ].join(";") +
            ":" +
            this.radio,
        });
      }, 500);
    },
  },
  computed: {
    ...mapState(["settingBaseinfo", "schoolMessaage"]),
    ...studentMapState([
      "yearsList",
      "facultyList"
    ]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
  mounted() {
    if (this.isMM) {
      this.$router.push("/room");
    }
    this.getAllSettingMessage();
    this.getCurrentSchoolMessage();
    this.getCanladerList();
    this.getClockTimeRange();
    this.getOrgList()
  },
  watch: {
    passwordVisible(val) {
      if (!val) {
        this.newPassword = "";
        this.confirmPassword = "";
      }
    },
    phoneVisible(val) {
      if (!val) {
        this.newPhone = "";
        this.confirmPhone = "";
      }
    },
    settingInfo(val) {
      console.log(val)
    },
    settingBaseinfo(value) {
      this.settingInfo = value
        .slice(1, 100)
        .map((it) => ({ ...it, checked: it.paramValue == 1 }));
      this.clockSwitch = value.find((it) => it.paramCode === "clockSwitch");
      this.clockSwitch.checked = this.clockSwitch.paramValue == 1;
      const unClockInfo = value[0];
      const unclockVal = unClockInfo.paramValue;
      const unclockgg = unclockVal ? unclockVal.split(":") : [];
      this.radio = unclockgg[1] ? +unclockgg[1] : 0;
      const ls = unclockgg[0] ? unclockgg[0].split(";") : ["", ""];
      this.cycleValue = {
        0: ls[0],
        1: ls[1],
      };
      this.schoolYearRange = (
        value
          .find((it) => it.paramCode == "schoolYearRange")
          ?.paramValue?.split("-") ?? [
          new Date().getFullYear() - 6,
          new Date().getFullYear(),
        ]
      ).map((it) => new Date(it));
      this.dormTypeList = value.find(it => it.paramCode == 'dormType').paramValue.split('-')
    },
    range(val) {
      let start = +val.start;
      const end = +val.end;
      let vacaIds = [];
      let vacaNoIds = [];
      while (start <= end) {
        for (let daysItem of this.allDaysByYears) {
          const d = new Date(start);
          if (
            +new Date(daysItem.calendarDate) ==
            +new Date(
              d.getFullYear() +
              "-" +
              String(d.getMonth() + 1).padStart(2, "0") +
              "-" +
              String(d.getDate()).padStart(2, "0")
            )
          ) {
            if (daysItem.vacationFlag == 1) {
              vacaNoIds.push(daysItem.id);
            } else {
              vacaIds.push(daysItem.id);
            }
            // this.changeDays({
            //   ...daysItem,
            //   vacationFlag: daysItem.vacationFlag == 1 ? 0 : 1,
            // }).then(() => {
            //   this.getCanladerList();
            // });
          }
        }
        start = start + 1000 * 60 * 60 * 24;
      }
      let d = [];
      if (vacaIds.length) {
        d.push(
          this.batChangeDays({
            ids: vacaIds.join(","),
            vacationFlag: 0,
          })
        );
      }
      if (vacaNoIds.length) {
        d.push(
          this.batChangeDays({
            ids: vacaNoIds.join(","),
            vacationFlag: 1,
          })
        );
      }
      Promise.all(d).then(() => {
        this.getCanladerList();
      });
    },
    schoolMessaage(val) {
      if (val) {
        this.phone = val.phone;
      }
    },
    dateRangeList: {
      handler(val) {
        this.dateRangeAttr = val.reduce(
          (c, n) =>
            n.value
              ? [
                ...c,
                {
                  highlight: {
                    color: "red",
                    start: {
                      color: "orange",
                      fillMode: "solid",
                    },
                    base: {
                      color: "orange",
                      fillMode: "light",
                    },
                    end: {
                      color: "orange",
                      fillMode: "solid",
                    },
                  },
                  dates: { start: n.value[0], end: n.value[1] },
                },
              ]
              : c,
          []
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  background: #f0f0f0;
  padding: 30px 24px;

  i {
    font-size: 18px;
  }

  .setting-content {
    background: #fff;

    .setting-header {
      height: 60px;
      background: rgba(245, 245, 245, 0.4);
      padding: 0 24px;
    }

    .setting-body {
      padding: 24px;

      .setting-item {
        padding-bottom: 20px;

        .setting-title {
          padding-left: 10px;
          color: #222;
          font-weight: bold;
          margin: 24px 0;
          font-size: 16px;
        }

        .setting-item-content {
          padding: 10px 0;

          &:hover {
            background: #f6f6f6;
          }

          .setting-s-timer {
            padding: 6px 0;
          }
        }

        .setting-item-content-s {
          padding: 10px 0;

          .setting-s-timer {
            padding: 6px 0;
          }
        }

        .weekDaySet {
          padding: 0 15px;
          margin-right: 100px;

          .set-name {
            margin: 0 8px;
          }
        }
      }

      .setting-item+.setting-item {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.set-color {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 0 10px;
}

.primary {
  background: #e66700;
}

.green {
  background: #6dd400;
}

.red {
  background: red;
}
</style>