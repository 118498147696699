<template>
	<div>
		<el-tag :key="tag" v-for="tag in value" closable :disable-transitions="false" @close="handleClose(tag)">
			{{ tag }}
		</el-tag>
		<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
			@keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
		</el-input>
		<el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新的类型</el-button>

	</div>
</template>

<script>

export default {
	props: {
		value: Array
	},
	data() {
		return {
			inputVisible: false,
			inputValue: ''
		};
	},
	methods: {
		handleClose(tag) {
			this.$emit('input', this.value.filter(it => it != tag))
		},

		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {

				this.$emit('input', [...this.value, inputValue])
			}
			this.inputVisible = false;
			this.inputValue = '';
		}
	}

}
</script>

<style scoped>
.el-tag+.el-tag {
	margin-left: 10px;
}

.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>