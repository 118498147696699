<template>
  <div class="d-f" style="justify-contents: space-between">
    <div class="notice-slice">
      <el-tree ref="treeRef" :data="orgList" show-checkbox @check-change="handleCheckChange"></el-tree>
    </div>
    <div class="notice-slice">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="" style="flex:1">
      <el-input type="textarea" v-model="noticeValue"></el-input>
      <el-button style="margin-top:5px" @click="sendNotice">发送</el-button>
    </div>
  </div>
</template>

<script>
import treeCheckVue from "../room/treeCheck.vue";
import axios, {
  $get,
  $post,
  $put,
  $delete,
  $postFile,
  startLoading,
  endLoading,
} from "../../plugins/axios";
import { getSchoolId, getOrgId } from "../../views/common/store";
export default {
  name: "notice",
  // components: { treeCheckVue },
  data() {
    return {
      orgList: [],
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: false,
      noticeValue: "",
      amalyList: [],
      xilist: []
    };
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.checkedCities = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    getList() {
      $get("/lock/organizationInfo/getUniversityAllOrganizationInfos", {
        params: { id: getOrgId() },
      }).then((res) => {
        res.forEach(item => {
          if (item.subOrganizationList && item.subOrganizationList.length) {
            this.xilist = [...this.xilist, ...item.subOrganizationList.map(it => it.id)]
          } else {
            this.amalyList.push(item.id)
          }
        })

        const getListTransform = (list) => {
          return list.map(it => {
            return {
              id: it.id,
              label: it.orgName,
              parentId: it.parentId,
              children: getListTransform(it.subOrganizationList)
            }
          })
        }
        this.orgList = [{ id: 'all', label: "全校", children: getListTransform(res) }];
        console.log(this.orgList)
      });
    },
    getYears() {
      $get('/lock/studentInfo/findStudentEnrolYearByUniversityId').then(years => {
        this.cities = years;
      })
    },
    sendNotice() {
      const checked = this.$refs.treeRef.getCheckedNodes();
      if (checked.length === 0) {
        this.$message.error('请选择发送通知的院系')
        return;
      }
      console.log()
      if (this.checkedCities.length === 0) {
        this.$message.error('请选择发送通知的学年')
        return;
      }
      if (!this.noticeValue) {
        this.$message.error('请输入通知内容')
        return;
      }

      const params = {
        "noticeType": "1",
        "sendType": "3",
        "advertisingFlag": "0",
        "sendContent": this.noticeValue,
        "sendTarget": `${checked.reduce((c, n) => this.amalyList.includes(n.id) ? [...c, n.id] : c, []).join(',') || '-1'};${checked.reduce((c, n) => this.xilist.includes(n.id) ? [...c, n.id] : c, []).join(',') || '-1'};${this.checkedCities.join(',') }`,
        "universityId": getSchoolId()
      };
      $post('/lock/noticeInfo/sendNoticeInfo', params).then(()=>{
        this.$message.success('发送通知成功')
        // this.$refs.treeRef.setCheckedNodes([])
      })
    },
  },
  mounted() {
    this.getList();
    this.getYears();
  }
};
</script>

<style>
.notice-slice {
  width: 25%;
  min-height: 100px;
  flex-direction: column;
  justify-content: space-between;
}

.notice-left-cell {
  min-height: 48px;
  border: 1px solid #999;
}
</style>